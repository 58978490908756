@import 'variabili';


/* ML NORMALIZZO ICONE x Mobile: definisco in modo proporzionale l'ingombro per sfondo (.icon) e dell'elemento grafico interno (.iconpadd:before) */
.iconfull,
.iconpadd {
  position: relative;
  display: inline-block;
  height:1em;
  width:1em;
  line-height:1em;
  text-align:center;
  /* font-size:50px; /* NOTA font-size determina l'ingombro dell'icona, compreso lo sfondo (non serve modificare height, width, line-eight, ecc) */
  /* puoi defiirlo direttamente nella customizzaizone del layout */
}
/* icona occupa tutto il background */
.iconfull:before {
  display:block;
  position:absolute;
  font-size:1em; top:0; left:0;
}
/* icona con spazio rispetto al abackground */
.iconpadd:before {
  display:block;
  position:absolute;
  font-size:0.55em; top:0.4090909090909091em; left:0.4090909090909091em;
  /* se FS = font-size di :before, TL (valore top/left di :before) = (1-FS)/(2*FS) */
}

// GENERALI
html, body {
  height:100%;
}
body {
  margin:0;
  padding:0;
  font-family: Arial, sans-serif;
  color:$color_grigio;
  font-size:15px;
  background-image: url(../images/bg2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}


// preloader
#preloader {
  position:fixed;
  z-index:100;
  top:0;
  left:0;
  bottom:0;
  right:0;
  .preloader-overlay {
    position:absolute;
    width:100%;
    height:100%;
    background: #222;
    opacity:1;
    transition:all 0.5s ease-out;
  }
  .preloader-inner {
    font-family: Arial, Helvetica, Sans-serif;
    text-align: center;
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    .loader{
      width: 250px;
      height: 25px;
      border-radius: 14px;
      overflow: hidden;
      position: relative;
      background:rgba(0,0,0,0.2);
    }
    .progress-bar{
      height: inherit;
      width: 0%;
      border-radius: inherit;
      position: relative;
      overflow: hidden;
      background: $color_main;
    }
    .percentage{
      position: absolute;
      top: 6px;
      right: 5px;
      font-weight: bold;
      font-size: 12px;
      color: rgba(0,0,0,0.7);
    }
    span.preloader-text{
      display: none;
      &.loaded{
        color: $color_main;
      }
    }
  }

}


// COMING SOON
#comingsoon {
  width:60vw;
  transform:translate(-50% , -50%);
  position:fixed;
  top:50%;
  left:50%;
  text-align:center;
  h1, h2 {
    position:fixed;
    left:9999px;
    opacity:0;
  }
  h2 {
    font-weight:normal;
  }
  svg {
    max-width:100%;
    height:auto;
    #shaka,
    #bla1,
    #bla2,
    #surfer {
      fill: $color_main;
      stroke:#fff;
      stroke-width: 5px;
      opacity:0;
    }
    #shaka {
      transform-origin: 100% 100%;
    }
    #bla1 {}
    #bla2 {}
    #surfer {}
  }
  div.msg {
    font-size:1.2em;
    //opacity:0.5;
    margin-top:1.5em;
    text-transform: uppercase;
    background:$color_main;
    color:#fff;
    height:2em;
    line-height: 2em;
    border-radius:5px;
    opacity:0;
    position:relative;
  }
  @media#{$bp-min-768} {
    width:20vw;
  }
}


.trasferimento {
  background: #f90;
  color:#fff;
  opacity:0.8;
  position:fixed;
  padding:3px;
  font-size: 10px;
  bottom:0;
  right:0;
}




