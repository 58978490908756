// BREAKPOINT
$bp-max-359:  "only screen and (max-width: 359px)"; // @media#{$bp-max-359} { ... }
$bp-min-360:  "only screen and (min-width: 360px)"; // @media#{$bp-min-360} { ... }
$bp-max-479:  "only screen and (max-width: 479px)"; // @media#{$bp-max-479} { ... }
$bp-min-480:  "only screen and (min-width: 480px)"; // @media#{$bp-min-480} { ... }
$bp-max-767:  "only screen and (max-width: 767px)"; // @media#{$bp-max-767} { ... }
$bp-min-768:  "only screen and (min-width: 768px)"; // @media#{$bp-min-768} { ... }
$bp-max-1023: "only screen and (max-width:1023px)"; // @media#{$bp-max-1023} { ... }
$bp-min-1024: "only screen and (min-width:1024px)"; // @media#{$bp-min-1024} { ... }
$bp-max-1279: "only screen and (max-width:1279px)"; // @media#{$bp-max-1279} { ... }
$bp-min-1280: "only screen and (min-width:1280px)"; // @media#{$bp-min-1280} { ... }
$bp-max-1439: "only screen and (max-width:1439px)"; // @media#{$bp-max-1439} { ... }
$bp-min-1440: "only screen and (min-width:1440px)"; // @media#{$bp-min-1440} { ... }
$bp-min-1610: "only screen and (min-width:1610px)"; // @media#{$bp-min-1610} { ... }


//COLORI

$color_main : #ff9900;
$color_grigio : #999999;


//FONT

//@font-face {
//  font-family: 'Buenos Aires';
//  src: url('fonts/BuenosAires-Bold.eot');
//  src: url('fonts/BuenosAires-Bold.eot?#iefix') format('embedded-opentype'),
//  url('fonts/BuenosAires-Bold.woff2') format('woff2'),
//  url('fonts/BuenosAires-Bold.woff') format('woff'),
//  url('fonts/BuenosAires-Bold.ttf') format('truetype'),
//  url('fonts/BuenosAires-Bold.svg#BuenosAires-Bold') format('svg');
//  font-weight: bold;
//  font-style: normal;
//}